.crossBtn {
  color: #fff;
  opacity: 0;
  will-change: opacity;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.crossBtn:hover {
  color: #fafafa;
}
/*# sourceMappingURL=src/components/CrossButton.css.map */