
#chapter
	padding-bottom 100px
	position relative
	.practice
		margin-top 90px
		margin-bottom 40px

		h3
			font-family: NanumGothic;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			text-align: center;
			letter-spacing: -0.02em;
			color: #2E2E2E;

		p
			font-family: HK Grotesk Pro;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 15px;
			text-align: center;
			letter-spacing: -0.03em;
			color: #828282;

	.container
		padding 0 35px
	.title
		display inline-block
		font-family: NanumGothic;
		font-style: normal;
		font-weight: 800;
		font-size: 20px;
		line-height: 20px;
		letter-spacing: -0.02em;
		text-transform: uppercase;
		color: #000;
		padding-top 20px
	.background
		position: absolute;
		left: 0px;
		top: 0px;
		z-index -1
		width: 100%;

	.backgroundTop
		height: 633.94px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #FF5379 72.88%);
	.backgroundMid
		height: 62px;
		background: #FF5379
	.backgroundBot
		height: 202px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #FF5379 72.88%);
		transform: rotate(-180deg);
	.percent
		position absolute
		top 15px
		right 15px
	.buttons
		position relative
		margin 0 30px
	.score
		display inline-block
		font-family: Neue Montreal;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 27px;
		img
			margin-right 4px
