.Book {
  text-align: center;
  margin-top: 50px;
}
.Book span {
  color: #0097fe;
  font-family: 'Neue Montreal';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  font-size: 11px;
  background: #fff;
  background-clip: padding-box; /* !importanté */
  border: solid 2px transparent; /* !importanté */
  border-radius: 40px;
  padding: 10px 20px;
  position: relative;
}
.Book span:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0ff;
  z-index: -1;
  margin: -2px;
  border-radius: inherit;
  background: linear-gradient(100.57deg, rgba(0,151,254,0.6), #0097fe);
}
/*# sourceMappingURL=src/components/chapter/BookTeacherButton.css.map */