.percent {
  width: 46px;
  height: 46px;
  font-family: 'Neue Montreal';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  padding-top: 15px;
  color: #fff;
  position: relative;
}
.percent svg {
  position: absolute;
  transform: rotate(90deg);
  top: 0;
  left: 0;
}
.percent svg.circle {
  opacity: 0.4;
}
.percent svg.progress {
  opacity: 0.6;
}
.percent.dark {
  color: #ff5379;
  border-color: rgba(1,1,1,0.25);
}
.percent.dark .progress {
  stroke: #ff5379;
}
/*# sourceMappingURL=src/components/PercentCircle.css.map */