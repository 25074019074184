
.button
	padding 20px 10px
	width 200px
	margin auto
	background #fff
	margin-bottom 40px
	border-radius: 15px
	cursor pointer
	// box-shadow: 0px 10px 12px 4px rgba(0,0,0,0.1)
	margin-right 20px
	font-size 12px
	line-height 18px
	background-color #ece0fd
	&.left
		background-color #fdedd0
		margin-left 20px

