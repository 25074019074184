.FocusListBlock {
  padding: 7px 27px;
  background: #fff;
  background: linear-gradient(180deg, rgba(255,255,255,0.95) 0%, #fff 100%);
  box-shadow: 0px 12.6618px 18.9927px rgba(46,46,46,0.039), 0px 5.25905px 7.88858px rgba(46,46,46,0.03);
  border-radius: 20px;
  text-align: center;
  margin-top: 10px;
}
.FocusListBlock p {
  font-family: Neue Montreal;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #2e2e2e;
  padding: 0;
  margin: 0;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid rgba(46,46,46,0.1);
  position: relative;
}
.FocusListBlock p:last-child {
  border: none;
}
.FocusListBlock p span {
  position: absolute;
  top: 24px;
  left: 0;
  font-family: HK Grotesk Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.03em;
  color: #828282;
}
/*# sourceMappingURL=src/components/chapter/FocusListBlock.css.map */