#chapter {
  padding-bottom: 100px;
  position: relative;
}
#chapter .practice {
  margin-top: 90px;
  margin-bottom: 40px;
}
#chapter .practice h3 {
  font-family: NanumGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #2e2e2e;
}
#chapter .practice p {
  font-family: HK Grotesk Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #828282;
}
#chapter .container {
  padding: 0 35px;
}
#chapter .title {
  display: inline-block;
  font-family: NanumGothic;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #000;
  padding-top: 20px;
}
#chapter .background {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  width: 100%;
}
#chapter .backgroundTop {
  height: 633.94px;
  background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, #ff5379 72.88%);
}
#chapter .backgroundMid {
  height: 62px;
  background: #ff5379;
}
#chapter .backgroundBot {
  height: 202px;
  background: linear-gradient(180deg, rgba(255,255,255,0.5) 0%, #ff5379 72.88%);
  transform: rotate(-180deg);
}
#chapter .percent {
  position: absolute;
  top: 15px;
  right: 15px;
}
#chapter .buttons {
  position: relative;
  margin: 0 30px;
}
#chapter .score {
  display: inline-block;
  font-family: Neue Montreal;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 27px;
}
#chapter .score img {
  margin-right: 4px;
}
/*# sourceMappingURL=src/views/Chapter.css.map */