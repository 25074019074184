video {
  width: calc(100% - 66px);
  height: auto;
}
.modal {
  position: absolute;
  overflow: hidden;
  text-align: center;
  background: url("/img/lesson-video.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 999;
}
.subtitles {
  margin-top: 30px;
}
.video {
  opacity: 0;
  will-change: opacity transform;
  cursor: pointer;
  width: calc(100% - 66px);
  margin: auto;
  margin-top: 35px;
  height: 170px;
  position: relative;
  margin-bottom: 50px;
  overflow: hidden;
  background: linear-gradient(180deg, #ffa1b7 0%, #ff5379 100%);
  box-shadow: 0px 100px 80px rgba(255,33,123,0.04), 0px 41.7776px 33.4221px rgba(255,33,123,0.05), 0px 22.3363px 17.869px rgba(255,33,123,0.042), 0px 12.5216px 10.0172px rgba(255,33,123,0.035), 0px 6.6501px 5.32008px rgba(255,33,123,0.028), 0px 2.76726px 2.21381px rgba(255,33,123,0.02);
  border-radius: 20px;
}
.video .text {
  color: #fff;
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-align: left;
}
.video .text h2,
.video .text h4 {
  padding: 0;
  margin: 0;
}
.video .text h2 {
  font-family: NanumGothic;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: -0.02em;
}
.video .text h4 {
  font-family: HK Grotesk Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.03em;
  text-align: left;
  margin-top: 5px;
}
.video .mainImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  mix-blend-mode: soft-light;
  opacity: 0.4;
}
.video:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.video.hidden {
  background: none;
}
.video .play {
  margin-top: 40px;
  opacity: 0.9;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
  width: 60px;
  height: auto;
}
.video:hover .play {
  opacity: 1;
}
/*# sourceMappingURL=src/components/VideoLesson.css.map */