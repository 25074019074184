.TestChallengeBlock {
  padding: 40px 28px;
  background: #fff;
  color: #fff;
  background: linear-gradient(180deg, rgba(0,151,254,0.5) 0%, #0097fe 100%);
  box-shadow: 0px 41.7776px 33.4221px rgba(8,181,255,0.05), 0px 22.3363px 17.869px rgba(8,181,255,0.042), 0px 12.5216px 10.0172px rgba(8,181,255,0.035), 0px 6.6501px 5.32008px rgba(8,181,255,0.028), 0px 2.76726px 2.21381px rgba(8,181,255,0.02);
  border-radius: 20px;
  text-align: left;
  position: relative;
}
.TestChallengeBlock img {
  height: 81px;
  position: absolute;
  top: 40px;
  right: 20px;
}
.TestChallengeBlock .title {
  font-family: NanumGothic;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #fff;
  padding: 0;
  margin: 0;
}
.TestChallengeBlock p {
  font-family: Neue Montreal;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  max-width: 150px;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}
/*# sourceMappingURL=src/components/chapter/BookNowBlock.css.map */