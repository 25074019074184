
	.percent
		width 46px
		height 46px
		font-family: 'Neue Montreal'
		font-style: normal
		font-weight: bold
		font-size: 12px
		text-align: center
		padding-top 15px
		color #fff
		position relative
		svg
			position absolute
			transform rotate(90deg)
			top 0
			left 0
			&.circle
				opacity 0.4
			&.progress
				opacity 0.6
		&.dark{
			.progress{
				stroke #FF5379
			}
			color #FF5379
			border-color: rgba(1, 1, 1, 0.25)
		}
