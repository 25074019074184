
.button
	width 78px
	height 78px
	background #fff
	display inline-block
	box-sizing: border-box
	border-radius: 15px
	cursor pointer
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.25) 100%);
	user-select none
	position absolute
	z-index 100
	&.gold
		background: linear-gradient(180deg, #FFE993 0%, #FED530 100%);
		box-shadow: 0px 42px 63px rgba(46, 46, 46, 0.06), 0px 12.6618px 18.9927px rgba(46, 46, 46, 0.0390953), 0px 5.25905px 7.88858px rgba(46, 46, 46, 0.03), 0px 1.9021px 2.85314px rgba(46, 46, 46, 0.0209047);
	&.disabled
		border 2px rgba(255,255,255,0.3) solid
		background transparent
	.stars, .video, .bonus, .lock
		position relative
		margin-top 26px
		margin-left 10px
		img
			position absolute
			top 0
			left 0
			z-index 10
			&:nth-child(2)
				left 13px
				z-index 9
			&:nth-child(3)
				left 26px
				z-index 8
	.video
		position relative
		margin-top 26px
		margin-left 28px
	.bonus
		position relative
		margin-top 20px
		margin-left 11px
	.lock
		margin-top 24px
		margin-left 26px

