.button {
  width: 78px;
  height: 78px;
  background: #fff;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 15px;
  cursor: pointer;
  background: linear-gradient(180deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.25) 100%);
  user-select: none;
  position: absolute;
  z-index: 100;
}
.button.gold {
  background: linear-gradient(180deg, #ffe993 0%, #fed530 100%);
  box-shadow: 0px 42px 63px rgba(46,46,46,0.06), 0px 12.6618px 18.9927px rgba(46,46,46,0.039), 0px 5.25905px 7.88858px rgba(46,46,46,0.03), 0px 1.9021px 2.85314px rgba(46,46,46,0.021);
}
.button.disabled {
  border: 2px rgba(255,255,255,0.3) solid;
  background: transparent;
}
.button .stars,
.button .video,
.button .bonus,
.button .lock {
  position: relative;
  margin-top: 26px;
  margin-left: 10px;
}
.button .stars img,
.button .video img,
.button .bonus img,
.button .lock img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.button .stars img:nth-child(2),
.button .video img:nth-child(2),
.button .bonus img:nth-child(2),
.button .lock img:nth-child(2) {
  left: 13px;
  z-index: 9;
}
.button .stars img:nth-child(3),
.button .video img:nth-child(3),
.button .bonus img:nth-child(3),
.button .lock img:nth-child(3) {
  left: 26px;
  z-index: 8;
}
.button .video {
  position: relative;
  margin-top: 26px;
  margin-left: 28px;
}
.button .bonus {
  position: relative;
  margin-top: 20px;
  margin-left: 11px;
}
.button .lock {
  margin-top: 24px;
  margin-left: 26px;
}
/*# sourceMappingURL=src/components/ChapterButton.css.map */