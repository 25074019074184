.button {
  padding: 20px 10px;
  width: 200px;
  margin: auto;
  background: #fff;
  margin-bottom: 40px;
  border-radius: 15px;
  cursor: pointer;
  margin-right: 20px;
  font-size: 12px;
  line-height: 18px;
  background-color: #ece0fd;
}
.button.left {
  background-color: #fdedd0;
  margin-left: 20px;
}
/*# sourceMappingURL=src/components/VideoSubtitle.css.map */