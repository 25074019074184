
.TestChallengeBlock
	padding 26px 33px
	background #fff
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, #FFFFFF 100%);
	box-shadow: 0px 42px 63px rgba(46, 46, 46, 0.06), 0px 12.6618px 18.9927px rgba(46, 46, 46, 0.0390953), 0px 5.25905px 7.88858px rgba(46, 46, 46, 0.03), 0px 1.9021px 2.85314px rgba(46, 46, 46, 0.0209047);
	border-radius: 20px;
	text-align: left
	position relative

	.icon
		width 22px
		height 24px
		position absolute
		top 20px
		right 30px
	.title
		font-family: NanumGothic
		font-style: normal
		font-weight: 800
		font-size: 15px
		line-height: 24px
		letter-spacing: -0.02em
		text-transform: uppercase
		color: #2E2E2E
	&.isClose
		.icon, .title
			opacity 0.4

